<template>
  <div class="addposition">
    <!-- 职位基本信息 -->
    <div class="">
      <div class="a-tit font20">职位基本信息</div>
      <el-form
        :model="postruleForm"
        :rules="postrules"
        ref="postInfoForm"
        label-width="130px"
        class="demo-ruleForm from-box"
      >
        <el-form-item label="项目周期：" prop="startDate" class="xmzhouqi">
          <el-date-picker
            type="date"
            :picker-options="pickerOptions0"
            placeholder="起"
            v-model="postruleForm.startDate"
            class="zhuashou"
            style="width: 182px"
          ></el-date-picker>
          <span style="margin: 0 15px">-</span>
          <el-date-picker
            type="date"
            placeholder="止"
            :picker-options="pickerOptions1"
            v-model="postruleForm.endDate"
            class="zhuashou"
            style="width: 182px"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="职位名称：" prop="jobName">
          <el-input
            v-model="postruleForm.jobName"
            placeholder="请输入职位名称，如java开发程序员"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位描述：" prop="jobDescription">
          <el-input
            type="textarea"
            :autosize="{ minRows: 8, maxRows: 10 }"
            show-word-limit
            placeholder="请勿填写QQ、微信、电话等联系方式及特殊符号、性别歧视词、违反劳动 法相关内容，否则有可能会导致您的账号被禁。"
            v-model="postruleForm.jobDescription"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="招聘人数：" prop="jobSize">
          <el-input
            placeholder="请输入招聘人数"
            v-model="postruleForm.jobSize"
            style="width: 400px"
          >
            <template slot="append"> 人 </template>
          </el-input>
        </el-form-item>
        <el-form-item label="工作城市：" prop="city">
          <el-cascader
            v-model="cityCodeed"
            :options="options"
            :props="{ expandTrigger: 'hover' }"
            placeholder=""
            @change="handleChange"
            style="width: 400px"
          ></el-cascader>
          <span style="position: absolute; left: 15px; color: #606266"
                v-if="!cityCodeed.length">
            {{ postruleForm.city }}
          </span>
        </el-form-item>
        <el-form-item label="工作地点：" prop="workPlace">
          <el-input
            v-model="postruleForm.workPlace"
            placeholder="请选择工作地点"
            style="width: 400px"
            @click.native="workDialogVisible = true"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="薪资模式："
          :prop="zjType == '2' ? 'salaryHigh' : 'jobLevelEnd'"
        >
          <el-select
            v-model="zjType"
            placeholder="请选择模式"
            style="width: 130px; margin-right: 20px"
          >
            <el-option
              v-for="item in zjoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <!-- <el-select v-model="postruleForm.jibie" placeholder="请选择职级" style="width: 130px;margin-right: 20px;">
						<el-option v-for="item in gzoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select> -->
          <!-- 按职级范围 -->
          <el-select
              v-model="postruleForm.jobLevelStart"
              placeholder="请选择最低职级"
              style="width: 250px"
              v-if="zjType == '1'"
              @change="switchJobStartLevel"
          >
            <el-option
                v-for="item in jobLevelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

          <el-select
              v-model="postruleForm.jobLevelEnd"
              placeholder="请选择最高职级"
              style="width: 250px; margin-left: 20px"
              v-if="zjType == '1'"
              @change="switchJobEndtLevel"
          >
            <div v-for="item in jobLevelOptions" :key="item.value">
              <el-option
                  :label="item.label"
                  :value="item.value"
                  v-if="checkJobLevel(item)"
              >
              </el-option>
            </div>
          </el-select>

          <el-form-item v-if="zjType === '1' && jobLevelNullFlag">
            <div>尚未设置职级，<a @click="toJobLevel" style="color: #00BCFF">去设置</a></div>
          </el-form-item>
          <!-- 按薪资范围 -->
          <el-select
            v-model="postruleForm.salaryLow"
            @change="switchLow"
            placeholder="请选择最低薪资"
            style="width: 160px"
            v-if="zjType == '2'"
          >
            <el-option
              v-for="item in gzLowOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="postruleForm.salaryHigh"
            placeholder="请选择最高薪资"
            style="width: 160px; margin-left: 20px"
            v-if="zjType == '2'"
          >
            <div v-for="item in gzHighOptions" :key="item.value">
              <el-option
                :label="item.label"
                :value="item.value"
                v-if="item.value > postruleForm.salaryLow"
              ></el-option>
            </div>
          </el-select>
          <div v-if="zjType === '1' && jobLevelTipFlag" style="font-size: 10px; color: #e9a43d; margin-left: 153px">
            {{ jobLevelStartMoney | numFilter }}
            -
            {{ jobLevelEndMoney | numFilter }}
            {{ ' / 月' }}({{ ' 数据仅供参考！' }})
          </div>
        </el-form-item>
      </el-form>
      <!-- 职位要求 -->
      <div class="a-tit font20">职位要求</div>
      <el-form
        :model="postruleForm"
        :rules="postrules"
        ref="postNeedForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <div class="from-boxdown">
          <el-form-item label="工作技能：" prop="skillIds">
            <el-select
                v-model="skillType"
                placeholder="请选择技能类型"
                style="width: 210px; margin-right: 30px"
                @change="onSkillTypeChanged"
            >
              <el-option
                  v-for="item in skillTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>

            <el-select
              :value="getSkillName()"
              placeholder="请选择工作技能"
              style="width: 382px"
              @click.native="projectSkill"
            >
              <!-- <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="附加技能：" prop="additionalskill">
						<el-select v-model="postruleForm.additionalskill" placeholder="请选择工作技能" style="width: 210px;">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item> -->
          <br />
          <el-form-item label="学历：" prop="educationType">
            <el-select
              v-model="postruleForm.educationType"
              placeholder="请选择学历"
              style="width: 210px"
            >
              <el-option
                v-for="item in educationOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="工作经验：" prop="expYear">
						<el-select v-model="postruleForm.expYear" placeholder="请选择工作经验" style="width: 400px;">
							<el-option v-for="item in expyearOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item> -->
          <el-form-item label="工作经验：" prop="expHigh">
            <el-select
              v-model="postruleForm.expLow"
              @change="switchLow1"
              placeholder="最低"
              style="width: 126px; margin-right: 30px"
            >
              <el-option
                v-for="item in expLowOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select
              v-model="postruleForm.expHigh"
              placeholder="最高"
              style="width: 126px"
            >
              <div v-for="item in expHighOptions" :key="item.value">
                <el-option
                  :label="item.label"
                  :value="item.value"
                  v-if="item.value > postruleForm.expLow"
                ></el-option>
              </div>
            </el-select>
          </el-form-item>
          <br />
          <el-form-item label="证书要求：" prop="honorCertNames">
            <el-input
              v-model="postruleForm.honorCertNames"
              placeholder="请输入请输入证书名称"
              style="width: 400px"
            ></el-input>
          </el-form-item>
          <el-form-item label="外语能力：" :rules="languageFlag?[{required: true, message:'请选择语言等级', trigger: 'blur'}]:null">
            <el-select
              v-model="postruleForm.languageType"
              placeholder="请选择语种"
              @change="onLanguageChanged"
              style="width: 185px; margin-right: 30px"
            >
              <el-option
                v-for="item in languageTYpeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select
              v-model="postruleForm.languageLevel"
              placeholder="请选择语言等级"
              style="width: 185px"
            >
              <el-option
                v-for="item in languageOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <br />
          <el-form-item style="display: none" label="排序：" prop="orderNum">
            <el-input
              v-model="postruleForm.orderNum"
              placeholder="请输入排序"
              style="width: 400px"
            ></el-input>
          </el-form-item>
        </div>
        <!-- @click="submitForm('postruleForm')" -->
        <el-form-item
          ><el-button type="primary" @click="saveJob"
            >发布职位</el-button
          ></el-form-item
        >
      </el-form>
    </div>

    <!-- 发布成功弹窗 -->
    <el-dialog
      :visible.sync="releaseDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="release_box">
        <div class="ub-img">
          <img src="../../assets/images/position/gouChenggong.png" />
        </div>
        <div class="ub-text font22">发布成功</div>
      </div>
    </el-dialog>
    <!-- 工作地点弹窗 -->
    <!-- :close-on-click-modal="false" -->
    <el-dialog
      :visible.sync="workDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
      class="work-box-out"
    >
      <div class="work-box">
        <span class="closeicon" @click="closeworkDialogbox()">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font20">工作地点</div>
        <div class="frombox">
          <el-form
            :model="workForm"
            ref="workForm"
            label-width="150px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="街道/写字楼："
              prop="street"
              :rules="[{ required: true, message: '请填写真实地址' }]"
            >
              <el-select
                v-model="tipInput"
                class="tipInput"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="请输入行政区、街道、写字楼"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="tipChange"
                style="width: 100%"
              >
                <el-option
                  v-for="item in tips"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.district
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="门牌号："
              prop="housenumber"
              :rules="[{ required: true, message: '请填写真实地址' }]"
            >
              <el-input
                type="text"
                style="width: 100%"
                v-model="workForm.housenumber"
                placeholder="请输入行政区、街道、写字楼"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <img
              v-if="!markers.length"
              class="amap-wrapper"
              style="width: 100%"
              src="../../assets/images/common/map.png"
            />
            <div class="amap-wrapper" v-else style="width: 100%">
              <el-amap
                class="amap-box"
                :zoom="zoom"
                :center="center"
                :mapStyle="mapStyle"
              >
                <el-amap-marker
                  v-for="(marker, index) in markers"
                  :key="'marker' + index"
                  :position="marker.position"
                  :events="marker.events"
                  :icon="marker.icon"
                >
                </el-amap-marker>
                <el-amap-info-window
                  v-if="window"
                  :position="window.position"
                  :visible="window.visible"
                  :content="window.content"
                  :offset="window.offset"
                  :is-custom="true"
                >
                  <div id="info-window">
                    <p>{{ window.address }}</p>
                  </div>
                </el-amap-info-window>
              </el-amap>
            </div>
            <el-form-item>
              <el-button class="qux" @click="workDialogVisible = false"
                >取消</el-button
              >
              <el-button type="primary" @click="submitForm('workForm')"
                >保存地址</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <!-- 技能弹窗 -->
    <skill-selector></skill-selector>
  </div>
</template>

<script>
import location from "../../assets/images/size.png";
import { cityDataes } from "../../common/addAddress.js";
import Bus from "../../common/bus.js";
import { options } from "../../common/options.js";
import skillSelector from "./choosejineng";

export default {
  components: {
    skillSelector,
  },
  data() {
    return {
      zoom: 14,
      center: [],
      mapStyle: "amap://styles/8b6be8ec497009e17a708205348b899a", //设置地图样式
      markers: [],
      windows: [],
      tipInput: "",
      loading: false,
      map: undefined,
      tips: [],
      window: "",
      zjType: "",
      cityCodeed: [],
      cityCodeFull: "", //城市编码全部

      skillList: [], // JSON.parse(sessionStorage.getItem("skill")),
      skillFirstActive: 0,
      skillSecondActive: 0,
      skillLastActive: 0,
      skillNames: [],
      skillIds: [],
      skillType: 0,
      skillTypes: options.skillTypes,

      jobLevelIds: [],
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return (
            time.getTime() <
            new Date(this.postruleForm.startDate).getTime() +
              1 * 24 * 60 * 60 * 1000
          ); //减去一天的时间代表可以选择同一天;
        },
      },
      jobLevelStartMoney: 0,
      jobLevelEndMoney: 0,
      jobLevelTipFlag: false,
      postruleForm: {
        jobType: 1,
        jobName: "",
        jobDescription: "",
        jobSize: "",
        city: "", //城市编码
        workPlace: "",
        salaryLow: "",
        salaryHigh: "",
        jobLevelStart: "",
        jobLevelEnd: "",
        startDate: "",
        endDate: "",
        jobLevelIds: "",
        // 职位要求
        skillIds: "",
        educationType: "",
        expYear: "",
        expLow: "",
        expHigh: "",
        honorCertNames: "",
        languageType: "",
        languageLevel: "",
        orderNum: "",
      },
      postrules: {
        startDate: [
          { required: true, message: "请选择项目周期", trigger: "blur" },
        ],
        endDate: [
          { required: true, message: "请选择项目周期", trigger: "blur" },
        ],
        jobName: [
          { required: true, message: "请输入职位名称", trigger: "blur" },
          {
            min: 2,
            max: 12,
            message: "长度在 2 到 12 个字符",
            trigger: "blur",
          },
        ],
        jobDescription: [
          { required: true, message: "请输入职位描述", trigger: "blur" },
        ],
        jobSize: [
          { required: true, message: "请输入招聘人数", trigger: "blur" },
        ],
        city: [{ required: true, message: "请选择工作城市", trigger: "blur" }],
        workPlace: [
          { required: true, message: "请选择工作地点", trigger: "blur" },
        ],
        salaryLow: [
          { required: true, message: "请选择薪酬范围", trigger: "blur" },
        ],
        salaryHigh: [
          { required: true, message: "请选择薪酬范围", trigger: "blur" },
        ],
        expHigh: [
          { required: true, message: "请选择经验范围", trigger: "blur" },
        ],
        skillIds: [
          { required: true, message: "请选择工作技能", trigger: "blur" },
        ],
        // educationType: [
        //   { required: true, message: "请选择学历", trigger: "blur" },
        // ],
        expYear: [
          { required: true, message: "请选择工作经验", trigger: "blur" },
        ],
        jobLevelIds: [
          { required: true, message: "请选择职级", trigger: "blur" },
        ],
        jobLevelStart: [
          { required: true, message: "请选择最低职级", trigger: "blur" },
        ],
        jobLevelEnd: [
          { required: true, message: "请选择最高职级", trigger: "blur" },
        ],
      },
      // flag 判断语种是否必填用
      languageFlag: false,
      options: [],
      educationOptions: JSON.parse(sessionStorage.getItem("educationType")),
      expyearOptions: JSON.parse(sessionStorage.getItem("expYear")),
      gzLowOptions: options.gzLowOptions,
      gzHighOptions: options.gzHighOptions,
      zjoptions: [
        { value: "1", label: "按职级范围" },
        { value: "2", label: "按薪资范围" },
      ],
      jobLevelOptions: [],
      jobLevelNullFlag: false,
      languageTYpeOption: [],
      expHighOptions: options.expHighOptions,
      expLowOptions: options.expLowOptions,
      releaseDialogVisible: false, //发布成功弹窗
      workDialogVisible: false, //工作地点弹窗
      workForm: {
        street: "",
        housenumber: "",
      },
      cityInfo: "",
      workskill: "",
    };
  },
  computed: {
    languageOptions() {
      let arr = [];
      let languageType = JSON.parse(sessionStorage.getItem("languageType"));
      if (this.postruleForm.languageType) {
        if(this.postruleForm.languageType === '不填'){
          return arr;
        }
        languageType.map((item) => {
          if (this.postruleForm.languageType == item.id) {
            item.list.map((sub) => {
              let obj = {
                value: sub.id,
                label: sub.name,
              };
              arr.push(obj);
            });
          }
        });
      }
      return arr;
    },
  },
  beforeDestroy() {
    Bus.$off("itSkillStatus");
    Bus.$off("chipSkillStatus");
    // Bus.$off("nonTechStatus");
  },
  activated() {
    this.chishiaddress();
    this.getJobLevel();
    this.getLanguage();
    if (this.educationOptions === null || this.educationOptions.length === 0) {
      this.getEducationType();
    }
  },
  filters: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(1)
      let realVal = transformVal.substring(0, transformVal.length - 1)
      return Number(realVal)
    }
  },
  methods: {
    initData(){  // 初始化表格数据
      this.postruleForm.jobType = 1;
      this.postruleForm.jobName = "";
      this.postruleForm.jobDescription = "";
      this.postruleForm.jobSize = "";
      this.postruleForm.city = ""; //城市编码
      this.postruleForm.workPlace = "";
      this.postruleForm.salaryLow = "";
      this.postruleForm.salaryHigh = "";
      this.postruleForm.jobLevelStart = "";
      this.postruleForm.jobLevelEnd = "";
      this.postruleForm.startDate = "";
      this.postruleForm.endDate = "";
      this.postruleForm.jobLevelIds = "";
      this.postruleForm.skillIds = "";
      this.postruleForm.educationType = "";
      this.postruleForm.expYear = "";
      this.postruleForm.expLow = "";
      this.postruleForm.expHigh = "";
      this.postruleForm.honorCertNames = "";
      this.postruleForm.languageType = "";
      this.postruleForm.languageLevel = "";
      this.postruleForm.orderNum = "";
    },
    projectSkill() {
      //项目技能
      let event  = this.skillType === 1 ? "chipSkillStatus" : this.skillType === 2 ? "nonTechStatus" : "itSkillStatus";
      Bus.$emit(event, {
        show: true,
        skillIds: this.skillIds,
        callback: (arr) => {
          // this.skillNames = [];
          // this.skillIds = [];
          this.clearSkillItemByType(this.skillType);
          // console.log("choose.callback --- ", event, " --- skill list, arr = ", arr);
          arr.map((item) => {
            if (this.skillIds.indexOf(item.value) === -1) {
              let skillItem = {
                value: item.value,
                label: item.label,
                code: item.code,
              };
              this.skillList.push(skillItem);
              this.skillNames.push(item.label);
              this.skillIds.push(item.value);
            }
          });

          // this.projectExp.projectTechnology = this.projectSkillIds.join(",");
        },
      });
    },
    onSkillTypeChanged(typeId){
      // console.log("$change the skill type, value = ", typeId);
      this.skillType = typeId;
    },
    clearSkillItemByType(skillType) {
      let tempNames = [];
      let tempIds = [];
      let tempList = [];
      let codeStr = skillType === 1 ? "chip_skill" : skillType === 2 ? "non_tech_skill" : "skill";
      let mapList = this.skillList;
      mapList.map((skillItem) => {
        if (skillItem.code !== codeStr) {
          if (this.skillNames.indexOf(skillItem.label) !== -1) {
            tempNames.push(skillItem.label);
          }
          if (this.skillIds.indexOf(skillItem.value) !== -1) {
            tempIds.push(skillItem.value);
          }
          tempList.push(skillItem)
        }
      });
      this.skillNames = tempNames;
      this.skillIds = tempIds;
      this.skillList = tempList;
    },
    getEducationType(){
      // 通过api获取学历选项
      this.$api.getdict("get", { code: "education_type" }).then((res) => {
        let list = [];
        if(res.data !== null && res.data.length > 0){
          let tempflag = false;
          // console.log("通过api获取学历选项 $api.getdict.res = ", res.data);
          res.data.map((item) => {
            if(item.name === '不限'){
              tempflag = true;
            }
            let obj = {
              value: item.id,
              label: item.name,
            };
            list.push(obj);
          });
          if(!tempflag){
            list.push({ value: "526", label: "不限",});
          }
        } else {
          let obj1 = {
            value: "526",
            label: "不限",
          };
          list.push(obj1);
        }
        window.sessionStorage.setItem("educationType", JSON.stringify(list));
        this.educationOptions = list;
      });
    },
    tipChange(value) {
      for (const tip of this.tips) {
        if (value === tip.id) {
          this.workForm.street = tip.district + tip.name;
          this.cityInfo = tip;
          let markers = [];
          let windows = [];
          let basePosition = [tip.location.lng, tip.location.lat];
          // let icon = new AMap.Icon({
          // 	image: location,
          // 	size: new AMap.Size(2, 2),
          // 	imageSize: new AMap.Size(2, 2)
          // });
          // content添加相应的内容**********
          markers.push({
            position: basePosition,
            icon: new AMap.Icon({
              image: location,
              size: new AMap.Size(40, 40),
              imageSize: new AMap.Size(40, 40),
            }),
            text: "content1111",
            offset: [0, -50],
          });
          windows.push({
            position: basePosition,
            isCustom: true,
            offset: [10, -40], // 窗体偏移
            visible: true, // 初始是否显示
            address: tip.sb,
          });
          this.zoom = 15;
          this.markers = markers;
          this.windows = windows;
          this.center = [tip.location.lng, tip.location.lat];
          this.window = windows[0];
          break;
        }
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const that = this;
        AMap.plugin("AMap.Autocomplete", function () {
          // 实例化Autocomplete
          const autoOptions = {
            city: "全国",
          };
          const autoComplete = new AMap.Autocomplete(autoOptions);
          autoComplete.search(query, function (status, result) {
            // console.log(">>>>>>", result);
            // 搜索成功时，result即是对应的匹配数据
            that.tips = result.tips || [];
            that.loading = false;
          });
        });
      } else {
        this.tips = [];
      }
    },
    toJobLevel(){
      this.$router.push({
        path: "positionlevel",
      });
    },
    switchJobStartLevel(selectedId) {
      this.jobLevelTipFlag = true;
      this.jobLevelOptions.forEach((l) => {
        if (selectedId == l.value) {
          this.jobLevelStartMoney = l.type===1?(l.money*8*21.75):l.type===2?(l.money*21.75):(l.money);
        }
      });
    },
    switchJobEndtLevel(selectedId) {
      this.jobLevelTipFlag = true;
      this.jobLevelOptions.forEach((l) => {
        if (selectedId == l.value) {
          this.jobLevelEndMoney = l.type===1?(l.money*8*21.75):l.type===2?(l.money*21.75):(l.money);
        }
      });
    },
    checkJobLevel(levelItem){
      if(levelItem){
        if(levelItem.type===1){
          return levelItem.money*8*21.75>=this.jobLevelStartMoney;
        }
        if(levelItem.type===2){
          return levelItem.money*21.75>=this.jobLevelStartMoney;
        }
        if(levelItem.type===3){
          return levelItem.money>=this.jobLevelStartMoney;
        }
      }
      return false;
    },
    switchLow(e) {
      let arr = [];
      this.gzHighOptions = options.gzHighOptions;
      this.postruleForm.salaryHigh = "";
      this.gzHighOptions.map((item) => {
        if (item.value > e) {
          arr.push(item);
        }
      });
      this.gzHighOptions = arr;
    },
    switchLow1(e) {
      let arr = [];
      this.expHighOptions = options.expHighOptions;
      this.postruleForm.expHigh = "";
      this.expHighOptions.map((item) => {
        if (item.value > e) {
          arr.push(item);
        }
      });
      this.expHighOptions = arr;
    },
    getJobLevel() {
      //获取职级列表
      this.$api.getJoblevel("get").then((res) => {
        let arr = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.rankName,
            money: item.money,
            type: item.type,
          };
          if(item.type === 1){
            obj.label = obj.label + ": " + obj.money + " / 小时";
          }
          if(item.type === 2){
            obj.label = obj.label + ": " + obj.money + " / 天";
          }
          if(item.type === 3){
            obj.label = obj.label + ": " + obj.money + " / 月";
          }
          arr.push(obj);
        });
        this.postruleForm.jobLevelStart = "";
        this.postruleForm.jobLevelEnd = "";
        if (arr.length > 0) {
          this.jobLevelNullFlag = false;
        } else {
          this.jobLevelNullFlag = true;
        }
        this.jobLevelEndMoney = 0;
        this.jobLevelStartMoney = 0;
        this.jobLevelOptions = arr;
      });
    },
    onLanguageChanged(val){
      // console.log("onLanguageChanged ---- val = ", val)
      this.postruleForm.languageLevel = "";
      this.languageFlag = val > 0;
    },
    getLanguage() {
      //获取语言种类
      let languageType = JSON.parse(sessionStorage.getItem("languageType"));
      if (languageType === null || languageType.length === 0){
        // 通过api获取语种选项
        this.$api.getdict("get", { code: "language_type" }).then((res) => {
          window.sessionStorage.setItem("languageType", JSON.stringify(res.data));
          let list = [];
          res.data.map((item) => {
            let obj = {
              value: item.id,
              label: item.name,
            };
            list.push(obj);
          });
          this.languageTYpeOption = list;
        });
      } else {
        let temp = [];
        languageType.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          temp.push(obj);
        });
        this.languageTYpeOption = temp;
      }
      let flag = false;
      this.languageTYpeOption.map( v => {
        if (v.label === "不填"){
          flag = true;
        }
      })
      if(!flag){
        this.languageTYpeOption.unshift({value:-1, label:'不填'});
      }
      this.postruleForm.languageType = '不填';
    },
    getSkillName() {
      let skillNames = this.skillNames.join(",");
      return skillNames ? skillNames : "";
    },
    saveJob() {
      this.jobLevelIds = [
        this.postruleForm.jobLevelStart,
        this.postruleForm.jobLevelEnd,
      ];
      this.$refs.postInfoForm.validate((valid) => {
        if (valid) {
          this.postruleForm.jobLevelIds = this.jobLevelIds.join(",");

          this.postruleForm.skillIds = this.skillIds.join(",");
          this.$refs.postNeedForm.validate((valid) => {
            if (valid) {
              // this.postruleForm.startData = new Data(this.postruleForm.startData)
              // this.postruleForm.endData = new Data(this.postruleForm.endData)

              let cityMatchFlag = false;
              if(this.cityCodeed && this.cityCodeed.length > 0){
                if(this.cityInfo !== "" && this.cityCodeed.indexOf(this.cityInfo.adcode)!==-1){
                  cityMatchFlag = true;
                } else if(this.cityInfo === "" && this.postruleForm.workPlace.indexOf(this.postruleForm.city)===-1){
                  cityMatchFlag = true;
                }
              } else {
                if(this.cityInfo !== "" && this.cityInfo.district.indexOf(this.postruleForm.city)===-1){
                  cityMatchFlag = true
                } else if (this.cityInfo === "" && this.postruleForm.workPlace.indexOf(this.postruleForm.city)===-1){
                  cityMatchFlag = true;
                }
              }
              if(cityMatchFlag){
                this.$message({
                  message: "已选工作城市与工作地址不匹配，请重新确认！",
                  type: "warning",
                });
                return false;
              }

              let config = JSON.parse(JSON.stringify(this.postruleForm));
              if (this.postruleForm.startDate) {
                let startTime = new Date(config.startDate);
                let y = startTime.getFullYear();
                let m = startTime.getMonth() + 1;
                let d = startTime.getDate();
                config.startDate = y + "-" + m + "-" + d;
              }
              if (this.postruleForm.endDate) {
                let endTime = new Date(config.endDate);
                let y = endTime.getFullYear();
                let m = endTime.getMonth() + 1;
                let d = endTime.getDate();
                config.endDate = y + "-" + m + "-" + d;
              }
              delete config["jobLevelStart"];
              delete config["jobLevelEnd"];
              if (this.center.length > 0) {
                config["lng"] = this.center[0];
                config["lat"] = this.center[1];
              }

              if (this.postruleForm.educationType === '不限' || this.postruleForm.educationType === "") {
                this.postruleForm.educationType = "526";
              }

              if(config.languageType==='不填' || this.postruleForm.languageType === -1){
                config.languageType = "";
                config.languageLevel = "";
              } else {
                if(this.postruleForm.languageLevel === ""){
                  this.$message({
                    message: "已选有外语语种要求，请确认要求的语种等级！",
                    type: "warning",
                  });
                  return false;
                }
              }

              this.$api.savejob("post", config).then((res) => {
                // console.log(res);
                if (res.code != 10200) {
                  this.$alert(res.message, "提示", {
                    confirmButtonText: "确定",
                  });
                } else {
                  this.releaseDialogVisible = true;
                  setTimeout(() => {
                    this.releaseDialogVisible = false;
                    this.$router.push("/recruitmentlist");
                  }, 1000);
                }
              });
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      });
    },
    chishiaddress() {
      this.options = cityDataes.cityData;
    },
    closeworkDialogbox() {
      this.workDialogVisible = false;
    },
    handleChange(value) {
      this.options.map((item) => {
        if (item.value == value[0]) {
          item.children.map((sub) => {
            if (sub.value == value[1]) {
              this.postruleForm.city = sub.label;
            }
          });
        }
      });
      // console.log(this.ruleForm.cityCodeFull);
      // console.log(this.ruleForm.cityCode);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.postruleForm.workPlace = this.workForm.street + this.workForm.housenumber;
          this.postruleForm.workBuild = this.cityInfo.name;
          if(this.cityCodeed.length>0){
            this.cityCodeed = [];
          }
          this.postruleForm.city = this.cityInfo.district;
          this.workDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style type="text/css">
.tipInput {
  width: 300px;
}
.amap-wrapper {
  width: 500px;
  height: 250px;
  margin-bottom: 20px;
}
.el-form-item__label {
  color: #111111;
  /* font-weight: 600; */
}
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>

<style lang="less" scoped>
#info-window {
  padding: 0px 20px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.addposition {
  background-color: #ffffff;
  padding: 30px;
  // height: 100%;
}
.a-tit {
  color: #111111;
  font-weight: 600;
  margin-bottom: 30px;
}
.from-box {
  /deep/ .el-input__inner,
  /deep/ .el-textarea__inner {
    border-radius: 0px;
  }

  /deep/ .el-input-group__append,
  /deep/ .el-input-group__prepend {
    border-radius: 0 !important;
    background-color: #dddddd;
  }
}
/deep/ .el-button {
  border-radius: 0px;
}
.from-boxdown {
  /deep/ .el-input__inner,
  /deep/ .el-textarea__inner {
    border-radius: 0px;
  }
  width: 900px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.release_box {
  line-height: 3;
  padding: 40px 0;
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
  }
}
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.work-box-out {
  .work-box {
    position: relative;
    .wb-tit {
      padding: 17px 0;
      text-align: center;
      background-color: #f3f3f3;
      color: #000000;
      font-weight: 600;
    }
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    .frombox {
      padding: 50px 60px 30px 60px;
      .demo-ruleForm {
        button {
          width: 160px;
          height: 40px;
          background-color: #00bcff;
        }
        .qux {
          background-color: #fff;
          border: 1px solid #00bcff;
          color: #00bcff;
        }
      }
    }
  }
}

.skill-box-out {
  .skill-box {
    .sb-top {
      padding: 26px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #f3f3f3;
      /deep/ .el-input__inner {
        border-radius: 0px;
        background-color: #f3f3f3;
        border: none;
      }
      /deep/ .el-button {
        border-radius: 0px;
        background: #00bcff;
        width: 100px;
        border: none;
      }
      .st-left {
        color: #000000;
        font-weight: 600;
      }
      .st-center {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        margin: 0 30px;
      }
      .st-right {
      }
    }
  }
}
.c-main {
  display: flex;

  min-height: 400px;
  .cm-left {
    width: 33.33%;
    background-color: #ffffff;
    padding-top: 30px;
    .c-title {
      color: #111111;
      font-size: 18px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .t-active {
      color: #00bcff;
      font-weight: 600;
      background-color: #f8f7fc;
    }
    .t-active:before {
      position: absolute;
      content: "";
      width: 4px;
      height: 28px;
      // top: 0;
      background: #00bcff;
      left: 0px;
      transform: translate(0%, 0%);
    }
  }
  .cm-center {
    width: 33.33%;
    background-color: #f8f7fc;
    padding-top: 30px;
    .c-title {
      color: #111111;
      font-size: 16px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .b-active {
      color: #00bcff;
    }
  }
  .cm-right {
    width: 33.33%;
    background-color: #f2f1f6;
    padding-top: 30px;
    .c-title {
      color: #111111;
      font-size: 16px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .c-active {
      color: #00bcff;
    }
  }
}
.xmzhouqi {
  /deep/ .el-input__inner {
    border-radius: 0px;
    border: 1px solid #00bcff;
  }
  /deep/ .el-input__prefix {
    color: #00bcff;
  }
}
.zhuashou:hover {
  cursor: pointer;
}
</style>
